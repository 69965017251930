import React, { memo, ReactNode } from 'react'
import cls from './Drawer.module.scss'
import { Portal, PortalProps } from '../Portal/Portal'
import { classNames } from '../../lib/classNames'

export interface DrawerProps extends PortalProps {
  readonly className?: string
  readonly children: ReactNode
  readonly isOpen?: boolean
  readonly onClose?: () => void
  readonly contentId?: string
}

export const Drawer = memo((props: DrawerProps) => {
  const { className, children, contentId, isOpen, onClose, ...portalProps } =
    props

  return (
    <Portal {...portalProps}>
      <div
        className={classNames(cls.Drawer, [className], {
          [cls.opened]: isOpen,
        })}
      >
        <div className={cls.content} id={contentId}>
          {children}
        </div>
      </div>
    </Portal>
  )
})
