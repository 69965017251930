import React, {
  memo,
  useMemo,
  ReactNode,
  forwardRef,
  ElementType,
  ReactElement,
} from 'react'

import cls from './Text.module.scss'

import { classNames } from 'shared/lib/classNames'

export type TextVariants =
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'span'
  | 'small'
  | 'title'
  | 'subtitle1'
  | 'inheritSize'
  | 'middle'

export type TextFonts = 'poppins' | 'prompt' | 'inter' | 'default' | 'inherit'

export type TextPalette =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'inherit'
  | 'subtitle1'
  | 'dark'
  | 'gradientRed'
  | 'gradientPurple'

export type TextDecoration = 'none' | 'underline'

export type TextWeight = 'bold' | 'thin' | 'normal' | 'semibold' | 'medium'

export type TextInteraction = 'hover' | 'active' | 'focus' | 'all' | 'none'
export type TextAlign = 'start' | 'center' | 'end'

const DECORATION_CLASS_MAP: Record<TextDecoration, string> = {
  none: cls.noDecoration,
  underline: cls.underline,
}

const FONT_FAMILY_CLASS_MAP: Record<TextFonts, string> = {
  inter: cls.inter,
  inherit: cls.fontInherit,
  default: cls.fontDefault,
  poppins: cls.poppins,
  prompt: cls.prompt,
}

interface TextBaseProps {
  readonly variant?: TextVariants | 'mainTitle'
  readonly children?: ReactNode
  readonly palette?: TextPalette
  readonly italic?: boolean
  readonly font?: TextFonts
  readonly decoration?: TextDecoration
  readonly weight?: TextWeight
  readonly interaction?: TextInteraction
  readonly transition?: boolean
  readonly align?: TextAlign
}

export type TextProps<As extends ElementType = 'p'> =
  PolymorphicComponentPropWithRef<As, TextBaseProps>

type TextFC = <As extends ElementType = 'p'>(
  props: TextProps<As>
) => ReactElement | null

const TextComponent: TextFC = forwardRef(
  <As extends ElementType = 'p'>(
    props: TextProps<As>,
    ref?: PolymorphicRef<As>
  ): ReactElement => {
    const {
      as: Tag = 'p',
      children,
      variant = 'p',
      className: classNameProp,
      palette = 'primary',
      italic,
      font = 'poppins',
      decoration = 'none',
      weight = 'normal',
      interaction = 'none',
      transition,
      align = 'start',
      ...restProps
    } = props

    const className = useMemo(
      () =>
        classNames(
          cls[variant.toString()],
          [
            cls[palette],
            cls[`${palette}-${interaction}`],
            classNameProp,
            FONT_FAMILY_CLASS_MAP[font],
            DECORATION_CLASS_MAP[decoration],
            cls[weight],
            cls[align],
          ],
          {
            [cls.italic]: italic,
            [cls.transition]: transition,
          }
        ),
      [
        variant,
        palette,
        classNameProp,
        font,
        italic,
        decoration,
        weight,
        transition,
        interaction,
        align,
      ]
    )

    return (
      <Tag className={className} {...restProps} ref={ref}>
        {children}
      </Tag>
    )
  }
)

export const Text = memo(TextComponent) as typeof TextComponent
