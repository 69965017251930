import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import  Banner  from '../Banner/Banner'
import logo from 'shared/assets/logo.svg'
import { Image } from 'shared/ui/Image/Image'
import { classNames } from 'shared/lib/classNames'
import { checkIsMobile } from 'shared/lib/checkIsMobile'
import { ROUTER_PATHS } from 'shared/config/router/consts'

import cls from './Header.module.scss'
import { DesktopHeader } from './DesktopHeader/DesktopHeader'
import { MobileHeader } from './MobileHeader/MobileHeader.async'

export interface HeaderProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  readonly children?: ReactNode
  readonly containerClassName?: string
}

export const Header: FC<HeaderProps> = props => {
  const { className, children, containerClassName, ...restHeaderProps } = props

  return (
    <header
      className={classNames(cls.Header, [className])}
      {...restHeaderProps}
    >
      <div className={cls.in}>
        <div className={classNames(cls.contentBox, [containerClassName])}>
          <Link to={ROUTER_PATHS.Home.Home}>
            <Image
              src={logo}
              alt={'logo'}
              loading={'lazy'}
              width={67}
              height={24}
              className={cls.logo}
            />
          </Link>

          {checkIsMobile() ? (
            <MobileHeader>{children}</MobileHeader>
          ) : (
            <DesktopHeader>{children}</DesktopHeader>
          )}
        </div>
        <Banner/>
      </div>
    </header>
  )
}
