import { RouteProps } from 'react-router-dom'
import { ROUTER_PATHS } from './consts'

export type RoutePaths = NestedKeyOf<typeof ROUTER_PATHS>
export type RouterGroupNames = keyof typeof ROUTER_PATHS
export type RouterGroupSubRoutes = (typeof ROUTER_PATHS)[RouterGroupNames]

export type RouterGroupNamesWithoutNotFound = Exclude<
  RouterGroupNames,
  'NotFound'
>

export type ConfigRouterProps = Omit<
  RouteProps,
  'loader' | 'element' | 'errorElement' | 'children' | 'id' | 'path'
> & {
  id: RoutePaths
  path: string
}

export const ROUTER_CONFIG = Object.entries(ROUTER_PATHS).map(
  ([groupKey, paths]) => {
    const routesByGroup: ConfigRouterProps[] = []

    for (const pathKey in paths) {
      routesByGroup.push({
        id: `${groupKey}.${pathKey}` as RoutePaths,
        // @ts-ignore
        path: paths[pathKey],
      })
    }

    return [groupKey, routesByGroup] as [string, ConfigRouterProps[]]
  }
)
