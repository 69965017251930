import { FC } from "react";
import cls from "./Roadmap.module.scss";
import { YearHeading } from "./YearHeading";
import { MonthCard } from "./MonthCard";

const Year1Cards = [
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 1-6",
    bulletPoint: [
      "Chains Supported -Ethereum, Polygon, Arbitrum, Optimism, Mantle, Bitcoin.",
      "Chain Additions - Stacks, Avalanche, Polygon zkEVM, Base, Solana and more.",
    ],
  },
  {
    monthTitle: "Month 1",
    bulletPoint: [
      "CEX Listing on Tier 2 & Tier 3 Exchanges: Launch on smaller centralised exchanges to increase accessibility and liquidity.",
      "Integrate Bitcoin L2: Enhance the platform by integrating Bitcoin Layer 2 chains (Stacks to begin).",
      "Enhanced Liquidity on DEXs: Improve liquidity on decentralised exchanges to support seamless trading.",
    ],
  },
  {
    monthTitle: "Month 2",
    bulletPoint: [
      "Simplified and Anonymous Onboarding: Streamline the onboarding process to make it easier and more private for new users (Signups won’t require email).",
      "Launch WalletConnect: Enable WalletConnect integration for secure and convenient wallet connections across all the dApps on supported EVM chains",
    ],
  },
  {
    monthTitle: "Month 3",
    bulletPoint: [
      "Launch Web Portfolio Viewer: Introduce a web-based tool for users to view and manage their portfolios on multiple chains.",
      "CEX Listing on Binance: List the token on Binance, one of the largest exchanges, to significantly increase visibility and trading volume.",
      "Begin AI-based Transaction Monitoring and Flagging: Implement initial AI tools to monitor transactions and flag suspicious activities.",
    ],
  },
  {
    monthTitle: "Months 4-6",
    bulletPoint: [
      "Introduce Bitcoin L2 Swaps: Enable swapping capabilities with Bitcoin Layer 2 solutions.",
      "Launch DeFi Access: Provide users with access to decentralised finance (DeFi) protocols. (Available on all supported chains)",
      "Implement Crosschain Swaps (Bridging + Swaps): Facilitate seamless asset transfers across different blockchain networks.",
      "Launch Staking Feature: Allow users to stake their tokens and earn rewards.",
    ],
  },
  {
    monthTitle: "Months 7",
    bulletPoint: [
      "Introduce In-wallet Quests: Gamify the user experience with in-wallet quests to engage and incentivize users.",
    ],
  },
  {
    monthTitle: "Months 8-9",
    bulletPoint: [
      "Rollout Card Based Recovery - Implement card-based recovery methods for account security.",
      "Enable Social Recovery - Introduce social recovery options to enhance account safety.",
    ],
  },
  {
    monthTitle: "Months 10-12",
    bulletPoint: [
      " Implement Multichain SafleID: Launch a multichain identity solution to unify user experiences across different blockchains.",
      "Begin SafleID Migrations: Start migrating existing users to the new SafleID system.",
      "Continued Chain Integrations and Security Enhancements: Ongoing efforts to integrate additional blockchains and improve platform security.",
    ],
  },
];

const Year2Cards = [
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 13-14",
    bulletPoint: [
      "Enhance Portfolio View with AI-based Insights: Upgrade the portfolio viewer with AI-generated insights to help users make informed decisions.",
      "Integrate Account Abstraction: Simplify account management with account abstraction techniques.",
    ],
  },
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 15-16",
    bulletPoint: [
      "Introduce Multisig Wallets: Enhance security by supporting multi-signature wallets.",
      "Launch Intent-based Usage of Wallet (driven by AI): Introduce AI-driven wallet functionalities based on user intents for a smarter experience.",
    ],
  },
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 17-18",
    bulletPoint: [
      "Launch M2M Wallets and Machine Economy Features: Enable machine-to-machine (M2M) wallet interactions to support the emerging machine economy.",
      "Continued UI/UX Enhancements: Ongoing improvements to user interface and user experience.",
    ],
  },
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 19-20",
    bulletPoint: [
      "Implement Hardware Wallet: Introduce support for hardware wallets to enhance security.",
      "Enhance Swaps on Multiple Chains: Extend swap functionalities to support more blockchain networks beyond Polygon and Ethereum.",
    ],
  },
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 21-22",
    bulletPoint: [
      "Advanced AI-based Transaction Monitoring and Flagging: Improve AI algorithms for better transaction monitoring and fraud detection.",
      "Continued Security Enhancements: Ongoing efforts to bolster platform security.",
    ],
  },
  {
    // heading: { title: "some", subtitle: "somethin2" },
    monthTitle: "Months 23-24",
    bulletPoint: [
      "Further Optimise Existing Features: Regular updates and optimizations based on user feedback and market trends to ensure top-notch performance and usability.",
    ],
  },
];

export const RoadMap: FC = () => {
  return (
    <div className={cls.backgroundPage}>
      <div className={cls.backgroundOverlay}></div>
      <div className={cls.content}>
        <h1>2 Year Product Roadmap</h1>
        <h3>(Starting June 2024)</h3>
        <div className={cls.rectangleBox}>
          <div className={cls.cardGroup}>
            <YearHeading //@ts-ignore
              title={"2024-25"}
              subtitle={"Foundation and Initial Enhancements"}
            />
            {Year1Cards.map((item) => (
              <MonthCard //@ts-ignore
                // heading={item.heading}
                monthTitle={item.monthTitle}
                bulletPoints={item.bulletPoint}
              />
            ))}
          </div>
          <div className={cls.cardGroup}>
            <YearHeading //@ts-ignore
              title={"2025-26"}
              subtitle={"Expansion and AI Integration"}
            />
            {Year2Cards.map((item) => (
              <MonthCard //@ts-ignore
                // heading={item.heading}
                monthTitle={item.monthTitle}
                bulletPoints={item.bulletPoint}
              />
            ))}
          </div>
        </div>
      </div>

      {/* <p>This page has a background image.</p> */}
    </div>
  );
};
