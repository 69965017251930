import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Text } from 'shared/ui/Text/Text'
import { Icon } from 'shared/ui/Icon/Icon'
import { Button } from 'shared/ui/Button/Button'
import { RouterGroupNames } from 'shared/config/router'
import { ROUTER_PATHS_MAP } from 'shared/config/router/consts'
import { ReactComponent as Arrow } from 'shared/assets/arrow.svg'

import cls from './MobileNav.module.scss'
import { MobileNavItem, MobileNavItemDrawerContent } from './MobileNavItem'

const ROUTER_GROUP_NAMES = Array.from(ROUTER_PATHS_MAP.keys())

export interface MobileNavProps {
  readonly onAfterRedirect?: () => void
}

const renderDrawerContent =
  (
    groupName: RouterGroupNames,
    onAfterRedirect: MobileNavProps['onAfterRedirect']
  ): MobileNavItemDrawerContent =>
  parameters =>
    (
      <ul className={cls.navList}>
        <Text
          weight={'semibold'}
          variant={'h5'}
          as={'li'}
          className={cls.backNavItem}
          onClick={parameters.onOpen}
          role={'button'}
        >
          <Icon Svg={Arrow} fillInherit className={cls.backArrow} />

          <Text weight={'semibold'} variant={'h5'}>
            Back to menu
          </Text>
        </Text>

        {Object.entries(ROUTER_PATHS_MAP.get(groupName) ?? {}).map(
          ([key, href]) => {
            return (
              <li key={key}>
                <Text
                  weight={'semibold'}
                  variant={'h5'}
                  as={Link}
                  className={cls.navItem}
                  onClick={onAfterRedirect}
                  to={href}
                >
                  {key}
                </Text>
              </li>
            )
          }
        )}

        <Button className={cls.connectWallet} borderRadius={'pill'}>
          <Link className='link' target='_blank' to={'https://app.getsafle.com/'}>
            <Text variant={'span'} as={'span'}>
              Connect wallet
            </Text>
          </Link>
        </Button>
      </ul>
    )

export const MobileNav: FC<MobileNavProps> = props => {
  const { onAfterRedirect } = props

  return (
    <div className={cls.MobileNav}>
      <ul className={cls.navList}>
        <>
          {ROUTER_GROUP_NAMES.map(groupName => {
            if (groupName === 'Home' || groupName === 'NotFound') {
              return null
            }

            return (
              <MobileNavItem
                key={groupName}
                id={'group'}
                drawerContent={renderDrawerContent(groupName, onAfterRedirect)}
              >
                {groupName}
                <Icon Svg={Arrow} fillInherit className={cls.nextArrow} />
              </MobileNavItem>
            )
          })}
        </>
        <Button className={cls.connectCarnival} borderRadius={'pill'}>
        <Link className='link' target='_blank' to={'https://xp.safle.com/'}>
          <Text variant={'span'} as={'span'}>
          Participate in Safle Carnival
          </Text>
        </Link>
      </Button>

        <Button className={cls.connectWallet} borderRadius={'pill'}>
          <Link className='link' target='_blank' to={'https://app.getsafle.com/'}>
            <Text variant={'span'} as={'span'}>
              Get Wallet
            </Text>
          </Link>
        </Button>
      </ul>
    </div>
  )
}
