import { FC } from 'react'

import { Icon } from 'shared/ui/Icon/Icon'
import { Text } from 'shared/ui/Text/Text'
import { Button } from 'shared/ui/Button/Button'
import { Dropdown } from 'shared/ui/Dropdown/Dropdown'
import { ROUTER_PATHS_MAP } from 'shared/config/router/consts'

import { createNavMenuItem } from '../lib/helpers/helpers'
import { ReactComponent as Arrow } from 'shared/assets/arrow.svg'

import cls from './Navigation.module.scss'

const ROUTER_GROUP_NAMES = Array.from(ROUTER_PATHS_MAP.keys())

export const Navigation: FC = () => {
  return (
    <>
      {ROUTER_GROUP_NAMES.map(key => {
        if (key === 'Home' || key === 'NotFound' || key === "Policies") {
          return null
        }

        const paths = ROUTER_PATHS_MAP.get(key)

        if (!paths) return null

        return (
          <Dropdown
            key={key}
            menuItems={createNavMenuItem(paths)}
            trigger={
              <Button
                variant={'clear'}
                className={cls.trigger}
                as={'div'}
                role={'button'}
              >
                <Text
                  variant={'span'}
                  as={'span'}
                  weight={'medium'}
                  interaction={'hover'}
                  transition
                >
                  {key}
                </Text>

                <Icon Svg={Arrow} fillInherit />
              </Button>
            }
          />
        )
      })}
    </>
  )
}
