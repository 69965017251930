export type ClassNamesMode = Record<string, boolean | undefined>
export const classNames = (
  cls: string,
  additional: Array<string | undefined> = [],
  mode: ClassNamesMode = {}
): string => {
  return [
    cls,
    ...additional.filter(Boolean),
    ...Object.entries(mode)
      .filter(([, modeValue]) => !!modeValue)
      .map(([clsValue]) => clsValue),
  ].join(' ')
}
