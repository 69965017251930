import { FC, PropsWithChildren } from 'react'

import { Link } from 'react-router-dom'
import { Text } from 'shared/ui/Text/Text'
import { Navigation } from 'features/Navigation'
import { Button } from 'shared/ui/Button/Button'

import cls from '../Header.module.scss'

export const DesktopHeader: FC<PropsWithChildren> = props => {
  return (
    <>
      <Navigation />

      {props.children}

      <Button className={cls.connectCarnival} borderRadius={'pill'}>
        <Link className='link' target='_blank' to={'https://xp.safle.com/'}>
          <Text variant={'span'} as={'span'}>
          Safle Carnival
          </Text>
        </Link>
      </Button>
    
    </>
  )
}
