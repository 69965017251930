import { FC } from "react";
import cls from "./Tokenomics.module.scss";
import { Text } from "shared/ui/Text/Text";
import tokenPie from "shared/assets/tokenpie.png";

export const Tokenomics: FC = () => {
  return (
    <div className={cls.backgroundPage}>
      <div className={cls.backgroundOverlay}></div>
      <div className={cls.content}>
        <h1>Safle Tokenomics</h1>
        <div className={cls.rectangleBox}>
          <img src={tokenPie} alt="Tokenomics" style={{ height: "100%" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: "20px 40px",
            }}
          >
            <p>
              Safle is an innovative platform with a well-structured tokenomics
              model at its core, designed to drive its decentralized ecosystem.
              The Safle token (SAFLE) plays a crucial role in ensuring security,
              utility, and governance within the platform. This article provides
              an in-depth look at the tokenomics of Safle, highlighting its
              various facets and future potential. The $SAFLE token is a utility
              token that helps token holders to propose changes and steer the
              Safle ecosystem by creating proposals and voting on them.
            </p>
            <h2 style={{ marginTop: "40px" }}>Token Details:</h2>
            <ul>
              <li>Token Name: Safle </li>
              <li>Token Symbol: SAFLE</li>
              <li>
                Token Contract Address:
                0xBb9FD66c0632bC92a631721B094233379970cF2e
              </li>
              <li>Token Type: Utility (In-wallet)</li>
              <li>Total Supply: 1,000,000,000</li>
              <li>Total Circulation: 178,000,000</li>
              <li>Base Chain: Polygon</li>
              <li>Bridge: Celer (Polygon, ETH, BSC)</li>
            </ul>
            <div style={{ marginTop: "40px" }}>
              Here is the utility of the $SAFLE token.
            </div>
            <ol>
              <li style={{ marginBottom: "20px" }}>
                Governance: SAFLE token holders are granted the power to
                participate in the decision-making processes of the platform.
                This decentralized governance model allows token holders to vote
                on key proposals, influencing the future development and
                direction of the Safle ecosystem. This ensures that the
                community has a say in important decisions, fostering a
                democratic and transparent environment.
              </li>
              <li style={{ marginBottom: "20px" }}>
                Staking: Tokens can be staked to earn a yield and get access
                premium services in the Safle Wallet. This enhances the wallet’s
                usability, provides liquidity for payments & incentives the
                users to hold Safle token for a longer period of time.
              </li>
              <li style={{ marginBottom: "20px" }}>
                Gas Fees: The token can be used to pay for services and
                transaction fees within the Safle ecosystem.The Token will be
                used as an intermediary for Gas Fees on multiple chains in order
                to provide users with seamless transactions across multiple
                chains without holding the native tokens, thus easing the user
                experience.
              </li>
              <li style={{ marginBottom: "20px" }}>
                Payments: Safle tokens will be used at the native currency of
                the ecosystem for utilizing features, rewarding developers to
                contribute to Safle & the community for their efforts in
                enhancing the Safle’s ecosystem.
              </li>
              <li style={{ marginBottom: "20px" }}>
                Incentives: $SAFLE token is used as an incentive for users and
                developers to engage with the platform using network effect
                economics.
              </li>
            </ol>

            <h2 style={{ marginTop: "40px" }}>Re-vesting</h2>
            <p>
              Safle team has revested their unlocked tokens for 2 years as a
              Proof of Commitment towards the community. Moreover, the unlocked
              supply for different pools have also been re-vested for 2 years.
              Only 17.8% tokens are in circulation as of 5th July 2024.
            </p>
            <h2 style={{ marginTop: "40px" }}>Distribution Model</h2>
            <p>
              Safle has implemented a strategic token distribution model to
              ensure fair allocation and support the platform's growth. The
              total supply of SAFLE tokens is fixed to 1,000,000,000 tokens,
              with the allocation structured as follows:
            </p>
            <br />

            <p>
              Users who stake their SAFLE tokens can earn rewards, promoting
              long-term holding and contributing to the stability and security
              of the network. This incentivizes users to remain engaged with the
              platform and benefit from their contributions.
            </p>
            <br />

            <p>
              Safle is committed to expanding the utility of its token and
              integrating more services into its ecosystem. The platform aims to
              continuously enhance the value proposition of the SAFLE token by
              introducing new features and functionalities. As the ecosystem
              grows, so will the opportunities for SAFLE token holders, ensuring
              a dynamic and evolving economic model.
            </p>
          </div>
        </div>
      </div>

      {/* <p>This page has a background image.</p> */}
    </div>
  );
};
