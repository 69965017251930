import { ElementType, forwardRef, memo, ReactElement, useMemo } from 'react'

import cls from './Button.module.scss'
import { classNames } from '../../lib/classNames'

export type ButtonVariant = 'outline' | 'contained' | 'clear'
export type ButtonPalette = 'primary' | 'secondary' | 'gradient' | 'dark-blue'
export type ButtonSize = 'sm' | 'md' | 'lg'
export type BorderRadius = 'rounded' | 'pill' | 'none'

const BORDER_RADIUS_MAP_CLASSES: Record<BorderRadius, string> = {
  pill: cls.roundedPill,
  rounded: cls.rounded,
  none: cls.square,
}

interface ButtonProps {
  readonly size?: ButtonSize
  readonly noBorder?: boolean
  readonly variant?: ButtonVariant
  readonly palette?: ButtonPalette
  readonly borderRadius?: BorderRadius
  readonly fullWidth?: boolean
}

export type ButtonPropsWithRef<As extends ElementType> =
  PolymorphicComponentPropWithRef<As, ButtonProps>

type ButtonFC = <As extends ElementType = 'button'>(
  props: ButtonPropsWithRef<As>
) => ReactElement | null

const ButtonComponent: ButtonFC = forwardRef(
  <As extends ElementType = 'button'>(
    props: ButtonPropsWithRef<As>,
    ref: PolymorphicRef<As>
  ): ReactElement => {
    const {
      children,
      className,
      variant = 'outline',
      palette = 'primary',
      size = 'md',
      noBorder = false,
      borderRadius = 'rounded',
      as: Tag = 'button',
      fullWidth = false,
      ...restButtonProps
    } = props

    const classNameValue = useMemo(
      () =>
        classNames(
          cls[variant],
          [
            cls[`${variant}--${palette}`],
            cls[size],
            BORDER_RADIUS_MAP_CLASSES[borderRadius],
            className,
          ],
          { [cls.noBorder]: noBorder, [cls.fullWidth]: fullWidth }
        ),
      [variant, palette, size, borderRadius, className, noBorder, fullWidth]
    )

    return (
      <Tag className={classNameValue} ref={ref} {...restButtonProps}>
        {children}
      </Tag>
    )
  }
)

export const Button = memo(ButtonComponent) as typeof ButtonComponent
