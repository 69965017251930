import { FC, SVGProps } from 'react'
import cls from './Icon.module.scss'
import { classNames } from '../../lib/classNames'

export interface IconProps extends SVGProps<SVGSVGElement> {
  readonly Svg: FC<SVGProps<SVGSVGElement>>
  readonly className?: string
  readonly fillInherit?: boolean
  readonly strokeInherit?: boolean
}
export const Icon: FC<IconProps> = props => {
  const { Svg, fill, className, fillInherit, strokeInherit, ...restProps } =
    props

  return (
    <Svg
      className={classNames(cls.icon, [className], {
        [cls.fillNone]: fill === 'none',
        [cls.fillInherit]: fillInherit,
        [cls.strokeInherit]: strokeInherit,
      })}
      {...restProps}
    />
  )
}
