import { FC } from 'react'
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from 'react-lazy-load-image-component'

export type ImageProps = LazyLoadImageProps

export const Image: FC<ImageProps> = props => {
  return <LazyLoadImage {...props} />
}
