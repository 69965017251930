import { FC } from "react";
import cls from "./PrivacyPolicy.module.scss";
import { Text } from "shared/ui/Text/Text";

export const PrivacyPolicy: FC = () => {
  return (
    <div className={cls.PrivacyPolicyContainer}>
      <Text as={"h3"} variant={"h3"} weight="bold">
        Safle Privacy Policy
      </Text>
      <br />
      <Text>
        This Privacy Policy is effective as of: 14 March 2023 (“Effective Date”)
      </Text>
      <br />
      <Text>
        Dulwich Ventures Limited, a company incorporated in the British Virgin
        Islands (“us”, “we”, or “Safle") is the licensee and publisher of the
        internet resource www.safle.com, www.getsafle.com and all the associated
        sub domains (authored by Inblox.me.Inc) of the or any replacement or
        successor domain name (“Platform” or “Website”) on the world wide web as
        well as the software and applications thereon, of the brand name
        “Safle”.
      </Text>
      <br />
      <Text>
        We value your privacy. In this Privacy Policy ("Policy"), we describe
        the information that we collect about you when you visit our website,
        https://getsafle.com/ (the "Website") and use the services available on
        the Website ("Services"), and how we use and disclose that information.
      </Text>
      <br />
      <Text>
        YOU UNDERSTAND THAT BY USING THE SITE OR SITE SERVICES AFTER THE
        EFFECTIVE DATE, YOU AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY
        CONSENT TO THE COLLECTION, UTILIZATION, SHARING AND DISCLOSURE OF YOUR
        INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT ACCEPT
        THE PRIVACY POLICY IN ITS ENTIRETY, YOU MUST NOT ACCESS OR USE THE SITE
        OR THE SITE SERVICES AFTER THE EFFECTIVE DATE.
      </Text>
      <br />
      <Text>
        IF YOU AGREE TO THE PRIVACY POLICY ON BEHALF OF AN ENTITY OR AGENCY, OR
        IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON BEHALF OF AN
        ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY
        TO BIND THAT ENTITY OR AGENCY TO THE PRIVACY POLICY AND AGREE THAT YOU
        ARE BINDING BOTH YOU AND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE.
        IN THAT EVENT, “YOU” AND “YOUR” WILL REFER AND APPLY TO YOU AND THAT
        ENTITY OR AGENCY.
      </Text>
      <br />
      <Text>
        Safle is committed to protecting and respecting your privacy. The
        purpose of this Privacy Policy is to describe:
      </Text>
      <ul className={cls.ol}>
        <li>
          <Text>
            The types of Personal Data we collect and how it may be used;
          </Text>
        </li>
        <li>
          <Text>Our use of cookies and similar technology;</Text>
        </li>
        <li>
          <Text>
            How and why we may disclose your Personal Data to third parties;
          </Text>
        </li>
        <li>
          <Text>The transfer of your Personal Data;</Text>
        </li>
        <li>
          <Text>Your statutory rights concerning your Personal Data;</Text>
        </li>
        <li>
          <Text>
            The security measures we use to protect and prevent the loss, misuse
            or alteration of Personal Data; and
          </Text>
        </li>
        <li>
          <Text></Text>Safle’s retention of your Personal Data.
        </li>
      </ul>
      <br />
      <ol className={cls.ol} style={{ listStyleType: "decimal" }}>
        <li>
          <Text>COLLECTION OF YOUR PERSONAL INFORMATION</Text>
          <Text>
            We collect personal information from the individuals who want to
            register on our website www.getsafle.com. This information is not
            accessible to the visitors using Safle’s website, either directly or
            by submitting a request. Individuals are required to create an
            account to be able to access certain portions of our Website,
            Individuals, if and when they create and use an account with Safle
            need to have a “Safle ID” which is unique to every individual
            registering on the website, they will be required to disclose and
            provide to Safle information including personal contact details like
            name and email address. Such information gathered shall be utilized
            to ensure greater customer satisfaction and help a customer satiate
            their needs.
          </Text>
          <Text>
            During the Account registration process and during your usage of
            Safle’s website, we will collect information such as your name and
            email address only. The information collected will not be shared to
            any other third party without your consent. During the registration
            process a Safle ID is allocated to each individual which is chosen
            by the individual and is unique in nature. A password with 5-16
            characters with a mix of uppercase, lowercase, and numbers has to be
            chosen by you, Safle has backup of these unique passwords but they
            are completely opaque from the Infrastructure. The employees of
            Safle also do not have access to these passwords. These unique
            passwords are completely secure and the same can be proven
            Technically.
          </Text>
          <Text>
            We also automatically collect certain computer, device and browsing
            information when you access the website or use Safle Services. This
            information is aggregated to provide statistical data about our
            users’ browsing actions and patterns, and does not personally
            identify individuals. This information may include:
          </Text>
          <ul className={cls.ol}>
            <li>
              <Text>
                Computer or mobile device information, including IP address,
                operating system, network system, browser type and settings;
              </Text>
            </li>
            <li>
              <Text>Website usage information.</Text>
            </li>
          </ul>
          <Text>
            The above information is collected for ensuring user security and
            block unusual requests to safeguard users and their funds. For the
            sake of clarity, we don't use or collect information pertaining to
            contacts, email or any other application present inside the user's
            device. The Camera of the user's device is used only for QR
            scanning, and doesn't save any data inside the application. Also,
            the Microphone is opaque from the interface.
          </Text>
        </li>
        <br />
        <li>
          <Text>Safle ID & IT’S USE</Text>
          <Text>
            Safle ID signifies a user name registered with our website or portal
            as a unique identifier for purposes including but not limited to
            digital identity of users, enablement of transfer of funds and
            transactions thereof, search and discovery of the user, assertion or
            confirmation of the user identity in an online environment,
            enablement of single sign on systems and other activities of
            decentralization of user data. The Safle IDs are in control with the
            users only which means Safle can access but cannot modify the
            information associated with the Safle ID like your wallets or make
            any transactions with it. Safle IDs cannot be deleted or
            transferred.
          </Text>
          <Text>
            You can connect your existing wallet to the service at your own
            risk, with Safle acting as a non-custodial wallet service or you may
            use the wallet file generated during the registration process for
            the purpose of pushing the wallet onto the Blockchain. The process
            is non-custodial in nature, the user always has their wallet private
            keys with them. Biometric data is locally maintained on OS and is
            opaque from Safle infrastructure making it completely secure. In
            case of PIN Based Transaction signing, keys are kept securely in
            Safle infrastructure using HSMs. Safle cannot end their service of
            offering wallet connections or allowing external wallets to be
            connected through their service as the same services are based on
            smart contracts and thus are immutable.
          </Text>
        </li>
        <br />
        <li>
          <Text>USE OF COOKIES AND SIMILAR TECHNOLOGY</Text>
          <Text>
            The Safle website uses cookies. Cookies are small text files that
            are placed on your computer by websites that you visit. They are
            widely used in order to make websites work, or work more
            efficiently, as well as to provide information to the owners of the
            site.
          </Text>
          <Text>
            Cookies are typically stored on your computer’s hard drive.
            Information collected from cookies is used by us to evaluate the
            effectiveness of our Site, analyze trends and administer the
            Platform. The information collected from cookies allows us to
            determine such things as which parts of our website are most visited
            and what difficulties our visitors may experience in accessing our
            website. With this knowledge, we can improve the quality of your
            experience on the Platform by recognizing and delivering more of the
            most desired features and information, as well as by resolving
            access difficulties. We also use cookies and/or a technology known
            as web bugs or clear gifs, which are typically stored in emails to
            help us confirm your receipt of, and response to, our emails and to
            provide you with a more personalized experience when using our Site.
          </Text>
          <Text>
            We may use one or more third-party Service providers, to assist us
            in better understanding the use of our Site. Our Service provider(s)
            will place cookies on the hard drive of your computer and will
            receive information that we select that will educate us on such
            things as how visitors navigate around our Site, what products are
            browsed and general Transaction information. Our Service provider(s)
            will analyse this information and provide us with aggregate reports.
            The information and analysis provided by our Service provider(s)
            will be used to assist us in better understanding our visitors’
            interests in our Site and how to better serve those interests. The
            information collected by our Service provider(s) may be linked to
            and combined with information that we collect about you while you
            are using the Platform. Our Service provider(s) is/are contractually
            restricted from using the information they receive from our Site for
            any other purpose than to assist us.
          </Text>
          <Text>
            If you want to avoid using cookies altogether, you can disable
            cookies in your browser. However, disabling cookies might make it
            impossible for you to use certain features of our website or
            Services, such as logging in to your Safle Account or making
            Transactions. Your use of our website or Service with a browser that
            is configured to accept cookies constitutes an acceptance of our and
            third-party cookies.
          </Text>
        </li>
        <br />
        <li>
          <Text>HOW SAFLE USES THE INFORMATION WE COLLECT</Text>
          <Text>
            Trusting a third party with your private information means that any
            action you want to perform with your funds should be confirmed by
            this third party. In this sense, custodial wallets are similar to
            traditional banks, where transactions might take up to one week
            because you need to get an approval of every step however small it
            might be. Conversely, with a non-custodial wallet, you’ll be able to
            perform instant withdrawals, which makes the process much easier and
            faster.
          </Text>
          <ol className={cls.ol} style={{ listStyleType: "decimal" }}>
            <li>
              <Text>How Personal Information Is Used</Text>
              <Text>
                We collect your personal information and aggregate information
                about the use of our Website and Services to better understand
                your needs and to provide you with a better Website experience.
                Specifically, we may use your personal information for any of
                the following reasons:
              </Text>
              <ul className={cls.ol}>
                <li>
                  <Text>
                    To provide our Services to you, including registering you
                    for our Services, verifying your identity and authority to
                    use our Services, and to otherwise enable you to use our
                    Website and our Services;
                  </Text>
                </li>
                <li>
                  <Text>
                    For customer support and to respond to your inquiries;
                  </Text>
                </li>
                <li>
                  <Text>For internal record-keeping purposes;</Text>
                </li>
                <li>
                  <Text>
                    To process billing and payment, including sharing with third
                    party payment gateways in connection with Website and/or
                    Safle's products and Services;
                  </Text>
                </li>
                <li>
                  <Text>
                    To improve and maintain our Website and our Services (for
                    example, we track information entered through the "Search"
                    function; this helps us determine which areas of our Website
                    users like best and areas that we may want to enhance; we
                    also will use for trouble-shooting purposes, where
                    applicable);
                  </Text>
                </li>
                <li>
                  <Text>
                    To periodically send promotional emails to the email address
                    you provide regarding new products from Safle, special
                    offers from Safle or other information about Safle that we
                    think you may find interesting;
                  </Text>
                </li>
                <li>
                  <Text>
                    To contact you via email, telephone, facsimile or mail, or,
                    where requested, by text message, to deliver certain
                    services or information you have requested;
                  </Text>
                </li>
                <li>
                  <Text>
                    For Safle's market research purposes, including, but not
                    limited to, the customization of the Website according to
                    your interests;
                  </Text>
                </li>
                <li>
                  <Text>
                    We may use your demographic information (i.e., age, postal
                    code, residential and commercial addresses, and other
                    various data) to more effectively facilitate the promotion
                    of goods and services to appropriate target audiences and
                    for other research and analytical purposes;
                  </Text>
                </li>
                <li>
                  <Text>
                    To resolve disputes, to protect ourselves and other users of
                    our Website and Services, and to enforce our Terms of Use;
                  </Text>
                </li>
                <li>
                  <Text>
                    We also may compare personal information collected through
                    the Website and Services to verify its accuracy with
                    personal information collected from third parties; and
                  </Text>
                </li>
                <li>
                  <Text>
                    We may combine aggregate data with the personal information
                    we collect about you.
                  </Text>
                </li>
                <li>
                  <Text>
                    From time to time, Safle may use personal information for
                    new and unanticipated uses not previously disclosed in our
                    Privacy Policy. If our information practices change
                    regarding information previously collected, Safle shall make
                    reasonable efforts to provide notice and
                    obtain consent of any such uses as may be required by law.
                  </Text>
                </li>
              </ul>
            </li>
            <li>
              <Text>Blockchain Transactions</Text>
              <ul className={cls.ol}>
                <li>
                  <Text>
                    Your blockchain transactions may be relayed through servers
                    (“nodes”) and will be publicly visible due to the public
                    nature of distributed ledger systems.
                  </Text>
                </li>
              </ul>
            </li>
            <li>
              <Text>Communication with Third-Party Servers</Text>
              <ul className={cls.ol}>
                <li>
                  <Text>
                    Exchange rates, balances, transactions and other blockchain
                    information may be read from or relayed to, third-party
                    servers. We cannot guarantee the privacy of your Internet
                    connection.
                  </Text>
                </li>
              </ul>
            </li>
            <li>
              <Text>Aggregate Usage Statistics</Text>
              <ul className={cls.ol}>
                <li>
                  <Text>
                    We may collect Safle services usage information in order to
                    improve function or UI, but will only use this information
                    in an aggregated, anonymized fashion, and never in
                    association with your name, email, or other personally
                    identifying information.
                  </Text>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <Text>SECURITY</Text>
          <Text>
            We retain information we collect as long as it is necessary and
            relevant to fulfil the purposes outlined in this policy. In
            addition, we retain personal information to comply with applicable
            law where required, prevent fraud, resolve disputes, troubleshoot
            problems, assist with any investigation, enforce our terms of use,
            and other actions permitted by law.
          </Text>
          <Text>
            To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorized
            use or disclosure of your personal information, the purposes for
            which we process your personal information and whether we can
            achieve those purposes through other means, and the applicable legal
            requirements.
          </Text>
          <Text>
            We employ procedural and technological security measures, which are
            reasonably designed to help protect your personal information from
            unauthorized access or disclosure. Safle may use encryption,
            passwords, and physical security measures to help protect your
            personal information against unauthorized access and disclosure. No
            security measures, however, are 100% complete. Therefore, we do not
            promise and cannot guarantee, and thus you should not expect, that
            your personal information or private communications will not be
            collected and used by others. You should take steps to protect
            against unauthorized access to your password, phone, and computer
            by, among other things, signing off after using a shared computer,
            choosing a robust password that nobody else knows or can easily
            guess, and keeping your log-in and password private. Safle is not
            responsible for the unauthorized use of your information or for any
            lost, stolen, compromised passwords, or for any activity on your
            Account via unauthorized password activity.
          </Text>
        </li>
        <br />
        <li>
          <Text>DISCLOSURE</Text>
          <Text>
            We may share the information that we collect about you, including
            your personal information, as follows:
          </Text>
          <ol className={cls.ol} style={{ listStyleType: "decimal" }}>
            <li>
              <Text>Information Disclosed to Protect Us and Others</Text>
              <Text>
                We may disclose your information including Personal Information
                if: (i) Safle reasonably believes that disclosure is necessary
                in order to comply with a legal process (such as a court order,
                search warrant, etc.) or other legal requirement of any
                governmental authority, (ii) disclosure would potentially
                mitigate our liability in an actual or potential lawsuit, (iii)
                reasonably necessary to enforce this Privacy Policy, our Terms
                of Use etc. (iv) disclosure is intended to help investigate or
                prevent unauthorized transactions or other illegal activities,
                or (v) necessary or appropriate to protect our rights or
                property, or the rights or property of any person or entity.
              </Text>
            </li>
            <li>
              <Text>
                Information Disclosed to Third Party Service Providers and
                Business Partners
              </Text>
              <Text>
                We may contract with various third parties for the provision and
                maintenance of the Website, Services and our business
                operations, and Safle may need to share your personal
                information and data generated by cookies and aggregate
                information (collectively, "information") with these vendors and
                service agencies. The vendors and service agencies will not
                receive any right to use your personal information beyond what
                is necessary to perform its obligations to provide the Services
                to you. If you complete a survey, we also may share your
                information with the survey provider; if we offer a survey in
                conjunction with another entity, we also will disclose the
                results to that entity.
              </Text>
            </li>
            <li>
              <Text>
                Disclosure to Non-Affiliated Third Parties in Furtherance of
                Your Request
              </Text>
              <Text>
                Your request for services may be shared with third party
                websites with whom we have a contractual relationship in order
                to provide your request with maximum exposure. The post on the
                third party website will include the details of your request,
                including your location, and other contact details. Interested
                bidders, however, will be able to click on your request on such
                third party site, and will be directed to our Website where they
                will have access to your contact details (Partial or complete),
                as would any other service provider on our Website interested in
                bidding on your request.
              </Text>
            </li>
            <li>
              <Text>Links to External Websites</Text>
              <Text>
                The Website may contain links to other websites or resources
                over which Safle does not have any control. Such links do not
                constitute an endorsement by Safle of those external websites.
                You acknowledge that Safle is providing these links to you only
                as a convenience, and further agree that Safle is not
                responsible for the content of such external websites. We are
                not responsible for the protection and privacy of any
                information which you provide while visiting such external
                websites and such sites are not governed by this Policy. Your
                use of any external website is subject to the terms of use and
                privacy policy located on the linked to external website.
              </Text>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <Text>
            WHAT CHOICES DO I HAVE REGARDING USE OF MY PERSONAL INFORMATION?
          </Text>
          <Text>
            We may send periodic promotional or informational emails to you. You
            may opt-out of such communications by following the opt-out
            instructions contained in the email. Please note that it may take up
            to 10 business days for us to process opt-out requests. If you
            opt-out of receiving emails about recommendations or other
            information we think may interest you, we may still send.
          </Text>
        </li>
        <br />
        <li>
          <Text>ACCOUNT PROTECTION</Text>
          <Text>
            Your password is the key to your Account. When creating your
            password you should use unique numbers, letters, special characters,
            and combinations thereof. In addition, DO NOT disclose your password
            to anyone. If you do share your password or your personal
            information with others, you are solely responsible for all actions
            taken via your Account. If you lose control of your password, you
            may lose substantial control over your personal information and be
            subject to legally binding actions taken on your behalf. Safle
            cannot and shall not be liable for any loss or damage arising from
            your loss of password.
          </Text>
        </li>
      </ol>
      <br />
      <Text>Privacy Policy Updates</Text>
      <br />
      <Text>
        THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE SET FORTH ABOVE. SAFLE
        MAY, IN ITS SOLE AND ABSOLUTE DISCRETION, CHANGE THIS POLICY FROM TIME
        TO TIME BY UPDATING THIS DOCUMENT. SAFLE WILL POST ITS UPDATED POLICY ON
        THE WEBSITE ON THIS PAGE. SAFLE ENCOURAGES YOU TO REVIEW THIS POLICY
        REGULARLY FOR ANY CHANGES. YOUR CONTINUED USE OF THIS WEBSITE AND/OR
        CONTINUED PROVISION OF PERSONAL INFORMATION TO US WILL BE SUBJECT TO THE
        TERMS OF THE THEN-CURRENT POLICY.
      </Text>
      <br />
      <br />
      <Text>Grievances</Text>
      <br />
      <Text>
        In terms of the Information Technology (Intermediaries Guidelines)
        Rules, 2011 and the Information Technology (Reasonable Security
        Practices and Procedures and Sensitive Personal Data or Information)
        Rules, 2011, any grievance or complaint may be intimated to Mr. Charles,
        Grievance Officer, who may be reached at hello@getsafle.com. The
        complaint shall be redressed within a period of 30 (Thirty) days.
      </Text>
      <Text>
        If you have any questions or comments about the Privacy Policy, please
        contact us at hello@getsafle.com. This Policy is incorporated into and
        is subject to the Safle Terms and Conditions of Use, which can be
        accessed at TnC. Your use of the Website and/or Services and any
        personal information you provide on the Website remains subject to the
        terms of this Policy and Safle's Terms of Use.
      </Text>
    </div>
  );
};
