import { DropdownItem } from 'shared/ui/Dropdown/Dropdown'

export const createNavMenuItem = (
  pathConfig: Record<string, string>
): DropdownItem[] => {
  return Object.entries(pathConfig).map(([key, path]) => ({
    label: key,
    href: path,
  }))
}
