import { FC, ReactNode, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Mission } from "pages/Mission";
import { HomePage } from "pages/HomePage";
import { RoutePaths, ROUTER_CONFIG } from "shared/config/router";
import { SafleIDPage } from "pages/WalletPage";
import { ContactUs } from "pages/ContactUs";
import { SafleVault } from "pages/SafleVault";
import { DeveloperSuitePage } from "pages/DeveloperSuitePage";
import { WalletUtility } from "pages/WalletUtility";
import { Whitepaper } from "pages/Whitepaper";
import { SafleKeyless } from "pages/SafleKeyless";
import { CareersPage } from "pages/CareersPage";
import { Crew } from "pages/Crew";
import { PrivacyPolicy } from "pages/PrivacyPolicy";
import { Tokenomics } from "pages/Tokenomics";
import { RoadMap } from "pages/Roadmap";
import { TermsAndConditions } from "pages/TermsAndConditions";
// import { SaflePioneers } from 'pages/SaflePioneers'

const APP_PAGES: Record<RoutePaths, ReactNode> = {
  "Home.Home": <HomePage />,
  // Safleverse
  "Safleverse.SafleID": (
    <div className="SafleIDPage">
      <SafleIDPage />
    </div>
  ),
  "Safleverse.SafleVault": <SafleVault />,
  // 'Safleverse.$SAFLE Token': <>$SAFLE Token</>,
  "Safleverse.Wallet Utility": <WalletUtility />,
  // "Safleverse.Whitepaper": <Whitepaper />,
  // 'Safleverse.Safle Pioneers': <SaflePioneers />,
  //Safle Token
  "$SAFLE Token.Tokenomics": <Tokenomics />,
  //@ts-ignore
  // "Safle Token.WhitePaper": <Whitepaper />,
  "$SAFLE Token.Roadmap": <RoadMap />,
  // Developers
  "Developers.Developer Suite": <DeveloperSuitePage />,
  "Developers.Safle Keyless": <SafleKeyless />,
  // About
  "About.Careers": <CareersPage />,
  "About.Mission": <Mission />,
  "About.Crew": <Crew />,
  "About.Whitepaper": <Whitepaper />,
  // 'About.Newsroom': <>Newsroom</>,
  // 'About.Journey Map': <>Journey Map</>,
  // 'About.Safle Labs': <>Safle Labs</>,
  "About.Contact Us": <ContactUs />,
  // Ecosystem
  // 'Ecosystem.Partnerships': <>Partnerships</>,
  // 'Ecosystem.Safle Pro': <>Safle Pro</>,
  // 'Ecosystem.Safle Ambassadors': <>Safle Ambassadors</>,
  // 'Ecosystem.SSafleProafle X': <>SSafleProafle X</>,
  "Policies.Privacy Policy": <PrivacyPolicy />,
  "Policies.Terms and Conditions": <TermsAndConditions />,
  "NotFound.NotFound": <>NotFound</>,
};

export const AppRouter: FC = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      {ROUTER_CONFIG.map(([, routeConfigs]) => {
        return routeConfigs.map((config) => (
          <Route
            key={config.id}
            path={config.path}
            element={APP_PAGES[config.id]}
          />
        ));
      })}
    </Routes>
  );
};
