import { ReactNode, ReactPortal } from 'react'
import { createPortal } from 'react-dom'

export interface PortalProps {
  children: ReactNode
  element?: Element | DocumentFragment | null
}

export const Portal = (props: PortalProps): ReactPortal => {
  const { children, element = document.body } = props

  return createPortal(children, element ?? document.body)
}
