import { ElementType, ReactNode, useState } from 'react'

import { Drawer } from 'shared/ui/Drawer/Drawer'
import { Text, TextProps } from 'shared/ui/Text/Text'

import cls from './MobileNav.module.scss'

export type MobileNavItemDrawerContent = (args: {
  isOpen?: boolean
  onOpen?: () => void
}) => ReactNode

export type MobileNavItemProps<As extends ElementType = 'p'> = TextProps<As> & {
  readonly drawerContent?: MobileNavItemDrawerContent
}

const setOpenCallback = (v: boolean): boolean => {
  const drawerGroupsElement = document.getElementById('mobile-nav-groups')

  if (v) {
    drawerGroupsElement?.setAttribute('style', 'overflow:auto')
  } else {
    drawerGroupsElement?.scroll(0, 0)
    drawerGroupsElement?.setAttribute('style', 'overflow:hidden')
  }

  return !v
}

export const MobileNavItem = <As extends ElementType = 'p'>(
  props: MobileNavItemProps<As>
): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const { children, id, drawerContent, ...restProps } = props

  const handleOpenDrawer = (): void => {
    setOpen(setOpenCallback)
  }

  return (
    <>
      <Text
        weight={'semibold'}
        variant={'h5'}
        as={'li'}
        className={cls.navItem}
        role={'button'}
        onClick={handleOpenDrawer}
        id={id}
        {...restProps}
      >
        {children}
      </Text>

      <Drawer
        isOpen={open}
        element={document.getElementById(id)}
        className={cls.drawer}
      >
        {drawerContent?.({
          isOpen: open,
          onOpen: handleOpenDrawer,
        })}
      </Drawer>
    </>
  )
}
