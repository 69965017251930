import { RouterGroupNames, RouterGroupSubRoutes } from "./index";

export const ROUTER_PATHS = {
  Home: {
    Home: "/",
  },
  Safleverse: {
    SafleID: "/safleId",
    "Wallet Utility": "/walletUtility",
    SafleVault: "/safleVault",
    // '$SAFLE Token': '/safleToken',
    // 'Safle Pioneers': '/pioneers',
  },
  "$SAFLE Token": {
    Tokenomics: "/tokenomics",
    // Whitepaper: "/whitepaper",
    Roadmap: "/roadmap",
  },
  Developers: {
    "Developer Suite": "/developerSuite",
    "Safle Keyless": "/safleKeyless",
  },
  About: {
    Mission: "/mission",
    Crew: "/crew",
    Careers: "/careers",
    Whitepaper: "/whitepaper",
    // Newsroom: '/newsroom',
    // 'Journey Map': '/journeyMap',
    // 'Safle Labs': '/safleLabs',
    "Contact Us": "/contactUs",
  },
  // Ecosystem: {
  //   'Safle Pro': '/saflePro',
  //   'SSafleProafle X': '/sSafleProafleX',
  //   'Safle Ambassadors': '/safleAmbassadors',
  //   Partnerships: '/partnerships',
  // },
  Policies: {
    "Privacy Policy": "/privacyPolicy",
    "Terms and Conditions": "/termsAndConditions",
  },
  NotFound: {
    NotFound: "/*",
  },
} as const;

export const ROUTER_PATHS_MAP = new Map<RouterGroupNames, RouterGroupSubRoutes>(
  // @ts-ignore
  Object.entries(ROUTER_PATHS)
);
