import React from "react";
import "./Banner.scss";
import lbank from "../../../shared/assets/lbank.png";
import quickswap from "../../../shared/assets/quickswap.png";
import matcha from "../../../shared/assets/matcha.png";
import dextools from "../../../shared/assets/dextools.png";
import cmc from "../../../shared/assets/cmc.png";
import coingecko from "../../../shared/assets/coingecko.png";

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-title">Available on</div>
      <div className="links-row">
        <div className="banner-content">
          <a
            href="https://www.lbank.com/trade/safle_usdt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={lbank} alt="LBANK" style={{ height: "10px" }} />
          </a>
        </div>
        <div className="divider"></div>
        <div className="banner-content">
          <a
            href="https://quickswap.exchange/#/swap?swapIndex=0&currency0=ETH&currency1=0x04b33078Ea1aEf29bf3fB29c6aB7B200C58ea126"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={quickswap} alt="QuickSwap" style={{ height: "20px" }} />
          </a>
        </div>
        <div className="divider"></div>
        <div className="banner-content">
          <a
            href="https://matcha.xyz/tokens/polygon/0xc2132d05d31c914a87c6611c10748aeb04b58e8f?buyChain=137&buyAddress=0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126&sellAmount=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={matcha} alt="Matcha" style={{ height: "10px" }} />
          </a>
        </div>
        <div className="divider"></div>
        <div className="banner-content">
          <a
            href="https://www.dextools.io/app/polygon/pair-explorer/0xef77d1e8fcf83a4818f6dffc73ef682794600c10"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={dextools} alt="DEXTools" style={{ height: "20px" }} />
          </a>
        </div>
        <div className="divider"></div>
        <div className="banner-content">
          <a
            href="https://coinmarketcap.com/currencies/safle/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={cmc} alt="CMC" style={{ height: "17px" }} />
          </a>
        </div>
        <div className="divider"></div>
        <div className="banner-content">
          <a
            href="https://www.coingecko.com/en/coins/safle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={coingecko} alt="CoinGecko" style={{ height: "20px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
