import { FC } from 'react'
import cls from './ComponentLoader.module.scss'

export const ComponentLoader: FC = () => {
  return (
    <div className={cls.loaderBox}>
      <span className={cls.loader} />
    </div>
  )
}
