import { FC } from "react";
import cls from "./Roadmap.module.scss";

//@ts-ignore
export const YearHeading: FC = ({ title, subtitle }) => {
  const [firstLine, secondLine] = subtitle.split("\n");
  return (
    <div className={cls.title}>
      <p>{title}</p>
      <p>{firstLine}</p>
      <p className={cls.thirdSubtitle}>{secondLine}</p>
    </div>
  );
};
