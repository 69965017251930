import { FC } from "react";
import cls from "./Roadmap.module.scss";

//@ts-ignore
export const MonthCard: FC = ({ heading, monthTitle, bulletPoints }) => {
  console.log(bulletPoints);
  return (
    <div className={cls.monthCard}>
      <div className={cls.monthTitle}>{monthTitle}</div>
      <ul>
        {bulletPoints &&
          bulletPoints.length > 0 && //@ts-ignore
          bulletPoints?.map((point) => (
            <li className={cls.bulletPoints}>{point}</li>
          ))}
      </ul>
    </div>
  );
};
