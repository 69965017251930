import { FC, Fragment, ReactElement, ReactNode } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'

import { Text } from '../Text/Text'
import cls from './Dropdown.module.scss'
import { classNames } from '../../lib/classNames'

export interface DropdownItem {
  label: string
  value?: string
  disabled?: boolean
  href?: string
  onClick?: () => void
}

export interface DropdownProps {
  readonly trigger?: ReactNode
  readonly menuClassName?: string
  readonly menuItemClassName?: string
  readonly menuItems: DropdownItem[]
  readonly enableTriggerAnimation?: boolean
}

export const Dropdown: FC<DropdownProps> = props => {
  const {
    trigger,
    menuClassName,
    menuItemClassName,
    menuItems,
    enableTriggerAnimation = true,
  } = props

  return (
    <Menu as={'div'} className={classNames(cls.Dropdown)}>
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(cls.trigger, [], {
              [cls.triggerOpenAnimation]: open && enableTriggerAnimation,
            })}
          >
            {trigger}
          </Menu.Button>

          <Transition
            enter={cls.enter}
            enterFrom={cls.enterFrom}
            enterTo={cls.enterTo}
            leave={cls.leave}
            leaveFrom={cls.leaveFrom}
            leaveTo={cls.leaveTo}
          >
            <Menu.Items className={classNames(cls.menu, [menuClassName])}>
              <div className={cls.menuIn}>
                {menuItems.map((item, index) => {
                  const content = ({
                    active,
                  }: {
                    active: boolean
                  }): ReactElement => (
                    <button
                      type='button'
                      disabled={item.disabled}
                      onClick={item.onClick}
                      className={classNames(cls.item, [menuItemClassName], {
                        [cls.active]: active,
                      })}
                    >
                      <Text as={'span'}>{item.label}</Text>
                    </button>
                  )

                  if (item.href) {
                    return (
                      <Menu.Item
                        as={Link}
                        to={item.href}
                        disabled={item.disabled}
                        key={`dropdown-key-${index}`}
                      >
                        {content}
                      </Menu.Item>
                    )
                  }

                  return (
                    <Menu.Item
                      key={`dropdown-key-${index}`}
                      as={Fragment}
                      disabled={item.disabled}
                    >
                      {content}
                    </Menu.Item>
                  )
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
